import { ButtonType } from "../../types";
import cls from './BorderedButton.module.scss';

function BorderedButton({ text, maxwidth, fontsize, fontweight, OnClickBtn }: ButtonType){
  return(
      <button
        className={cls.button}
        style={{ maxWidth: maxwidth, fontSize: fontsize, fontWeight: fontweight }}
        onClick={() => OnClickBtn()}
      >
        {text}
      </button>
  )
}
export default BorderedButton