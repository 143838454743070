import { useEffect, useRef } from "react";
import cls from "./Popup.module.scss";

type PropsModal = {
  open?: boolean;
  slug?: string;
  setClose: any;
  children: React.ReactElement;
};

function Popup({ children, setClose, open, slug }: PropsModal) {
  
  const handleOnCloseByProp = () => {
    setClose && setClose();
    // document.getElementById('header')?.classList.toggle('headerHide')
  };
  
  // useEffect(() => {
  //   document.getElementById('header')?.classList.toggle('headerHide')
  // }, [open])
  

  return (
    <>
      {(open || slug) && (
        <div  className={cls["popup"]} onClick={handleOnCloseByProp}>
          <div className={cls["popup__modal"]}>
            <div
              className={cls["popup__modal__content"]}
              onClick={(event) => event.stopPropagation()}
            >
              <>{children}</>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
