import { StartCollectOrderProps } from '../../../utils/types';
import cls from './StartCollectorOrder.module.scss'

function StartCollectOrder({handleBeginCollect, status}: StartCollectOrderProps){
  
	const change = () => {
		handleBeginCollect()
	};

  return (
    <> 
		<button
			onClick={() => change()}
			className={`${status !== 2 ? "bg-[#FDF8B8] text-[#9e9e9e]" : "cursor-pointer bg-[#f9eb1d] text-[#575757]"} text-base py-[14px] px-[18px] lg:py-[20px] lg:px-[30px] font-bold uppercase  rounded-[80px]`}
		>
			{status !== 2 ? "вы собираете заказ" : "приступить к заказу"}
		</button>
    </>
  )
}

export default StartCollectOrder