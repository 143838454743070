import cls from "./TabletOrderList.module.scss";
import TabletOrderCard from "../TabletOrderCard/TabletOrderCard";
import { TabletOrders } from "../../../redux/Tablet/types";
import { useDispatch } from "react-redux";
import { RootState, TypedDispatch } from "../../../redux/store";
import {
  setCloseOrders,
  setOpenOrders,
  setShowingOrder,
  setTabletCurrentPage,
} from "../../../redux/Tablet/TabletSlice";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";
import {
  getAssemblingOrders,
  getCollectorOrder,
  getWaitingOrders,
} from "../../../redux/Tablet/actions";
import Empty from "./Empty";

function TabletOrderList() {
  const dispatch = useDispatch<TypedDispatch>();
  const { waitingOrders, newOrders, assemblingOrders, activeTab, loading } =
    useSelector((state: RootState) => state.tablet);

  const handleOpenOrder = (card: TabletOrders) => {
    dispatch(setCloseOrders());
    dispatch(setShowingOrder(card));
    dispatch(setOpenOrders({activeTab, types: activeTab, ids: card.id }));
  };

  const setCurrentPage = (currentPage: number, totalPages: number) => {
    if (!loading && currentPage !== totalPages) {
      dispatch(setTabletCurrentPage(currentPage + 1));
      switch (activeTab) {
        case "new":
          dispatch(
            getCollectorOrder({ currentPage: newOrders.currentPage + 1 })
          );
          break;
        case "waiting":
          dispatch(
            getWaitingOrders({ currentPage: waitingOrders.currentPage + 1 })
          );
          break;
        case "assembling":
          dispatch(
            getAssemblingOrders({
              currentPage: assemblingOrders.currentPage + 1,
            })
          );
          break;
      }
    }
  };

  return (
    <div className={cls["orderList"]}>
           {activeTab === "new" && (
        <>
          {newOrders.orders.length > 0 ? (
            <>
              {newOrders.orders.map((item) => {
                return (
                  <TabletOrderCard
                    handleOpenOrder={() => handleOpenOrder(item)}
                    key={item.id}
                    deliver={item.deliver}
                    code={item.id}
                    active={item.active}
                    time={item.date}
                    date={item.date}
                    count={item.quantity}
                    inWork={item.status === 3}
                  />
                );
              })}
              {newOrders.count > 20 && (
                <>
                  <button
                    className={cls["orderList__paginationBtn"]}
                    onClick={() =>
                      setCurrentPage(
                        newOrders.currentPage,
                        newOrders.totalPages
                      )
                    }
                  >
                    открыть еще заказы
                  </button>
                </>
              )}
            </>
          ) : (
            <Empty text={'У вас нет новых заказов'}/>
          )}
        </>
      )}
 
      {activeTab === "waiting" && (
        <>
          {waitingOrders.orders.length > 0 ? (
            <>
              {waitingOrders.orders.map((item) => {
                return (
                  <TabletOrderCard
                    handleOpenOrder={() => handleOpenOrder(item)}
                    key={item.id}
                    deliver={item.deliver}
                    code={item.id}
                    active={item.active}
                    time={item.date}
                    date={item.date}
                    count={item.quantity}
                    inWork={item.status === 3}
                  />
                );
              })}
              {waitingOrders.count > 20 && (
                <>
                  <button
                    className={cls["orderList__paginationBtn"]}
                    onClick={() =>
                      setCurrentPage(
                        waitingOrders.currentPage,
                        waitingOrders.totalPages
                      )
                    }
                  >
                    открыть еще заказы
                  </button>
                </>
              )}
            </>
          ) : (
            <Empty text={'У вас нет готовых заказов'}/>
          )}
        </>
      )}
      {activeTab === "assembling" && (
        <>
          {assemblingOrders.orders.length > 0 ? (
            <>
              {assemblingOrders.orders.map((item) => {
                return (
                  <TabletOrderCard
                    handleOpenOrder={() => handleOpenOrder(item)}
                    key={item.id}
                    deliver={item.deliver}
                    code={item.id}
                    active={item.active}
                    time={item.date}
                    date={item.date}
                    count={item.quantity}
                    inWork={item.status === 3}
                  />
                );
              })}
              {assemblingOrders.count > 20 && (
                <>
                  <button
                    className={cls["orderList__paginationBtn"]}
                    onClick={() =>
                      setCurrentPage(
                        waitingOrders.currentPage,
                        waitingOrders.totalPages
                      )
                    }
                  >
                    открыть еще заказы
                  </button>
                </>
              )}
            </>
          ) : (
            <Empty text={'У вас активных заказов'}/>
          )}
        </>
      )}
      {loading && <Loader />}
    </div>
  );
}

export default TabletOrderList;
