import React from "react";
import cls from "./Loader.module.scss";

export default function Loader() {
  return (
    <div className={cls["lds-ring"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
