import axios from "axios";
import { refreshToken } from "../redux/User/action";

export interface RequestFunc {
  url: string
  body: null | object
  type: string
}

export interface CollectorsType {
  token: string
  rToken: string
  collector_phone: string
  address: string,
}

export default async ({
  url, body, type,
}: RequestFunc) => {
  let location = window.location.pathname.replaceAll('/', '');
  let tabletToken:CollectorsType = JSON.parse(localStorage.getItem('collectors') || '{}');
  const language = localStorage.getItem('i18nextLng');
  try {
    return await axios.request({
      method: type,
      baseURL: process.env.REACT_APP_BASE_URL,
      url,
      headers: { Authorization:tabletToken.token ? `Bearer ${tabletToken.token}` : "" },
      params: { language: (type === 'get') ? language : '' },
      data: body,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      try {
        const res = await refreshToken(axios, tabletToken);
        if (res.status >= 200 && res.status < 300) {
          return await axios.request({
            method: type,
            baseURL: process.env.REACT_APP_BASE_URL,
            url,
            headers: {
              Authorization: `Bearer ${res.data.access}`
            },
            data: body,
          });
        }
        return res;
      } catch (err: any) {
        console.log('try to 401 ERROR:', err);
        return err;
      }
    }
    throw error;
  }
};
