import { ReactChildrenComponent, errorStatusVariant } from "../../types";
import cls from './ErrorPopup.module.scss'

function ErrorPopup({ children, status }: ReactChildrenComponent) {
  return (
    <div style={{ background: status === errorStatusVariant.success ? errorStatusVariant.success : errorStatusVariant.error }} className={cls['ErrorCont']} >
      <>{children}</>
    </div >
  )
}
export default ErrorPopup