import {DetailProduct, TabletOrders, TabletOrdersTab} from "../redux/Tablet/types";
import { ComboFood, FoodsGlobal } from "./types";

export interface ProfileFilterQueryParamsType{
  currentPage: number
  date_gte: string | null;
  date_lte: string | null;
  deliver: boolean | null;
}

export const filterPhoneNum = (phoneNum: string) => '+' + phoneNum.replace(/\D/g, '')

export const parserForCollectorFoods = (foods: FoodsGlobal[]) => {
  const parsed = foods.map((item) => parseOrder(item)) as TabletOrders[]
  sortByDate(parsed)
  return parsed
}

export const sortByDate = (dateArray: TabletOrders[]): TabletOrders[] =>  dateArray.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

export const parseTime = (date: string) => {
  const originalDate = new Date(date);
  const formattedTime = `${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}`;
  return formattedTime
}

export default function parseOrder(obj: FoodsGlobal) {
  const order = {
    id: obj.id,
    quantity: obj.total_items,
    date: obj.date,
    status: obj.status,
    deliver: obj.deliver,
    time: obj.date,
    active: false,
    started: false,
    items: obj.items.map((item: any) => {
      if(item.food){
        return  {
          id: item.food.id,
          orderItemId: item.id,
          title: item.food.title,
          image: item.food?.image,
          status: item.status,
          quantity: item.quantity,
        }
      }else if(item.combo_food){
        return {
          id: item.combo_food.id,
          orderItemId: item.id,
          title: item.combo_food.title,
          image: item.combo_food?.image,
          status: item.status,
          quantity: item.quantity,
          changeDrink: item.change_drink,
          changeSauce: item.change_sauce
        }

      }
    })
  }

  return order
}

export const areAllCheckedTrue = (items:DetailProduct[]) => {
  for (let i = 0; i < items.length; i++) {
    if (!items[i].status) {
      return false;
    }
  }
  return true;
}

export const setShowingOrderToState = (tab: TabletOrdersTab, showingOrder: TabletOrders) => {
  const storedTab = tab.orders.map((item) => item.id === showingOrder.id ? showingOrder : item )
  return { ...tab, orders: storedTab}
}

export const parseDate = (date: string) => {
  if (date !== '') {
    
    const originalDate = new Date(date);
    const formattedTime = `${originalDate.getDate().toString().padStart(2, '0')}.${originalDate.getMonth()+1 < 9 ? '0'+(originalDate.getMonth()+1): (originalDate.getMonth()+1)}.${originalDate.getFullYear().toString().padStart(2, '0').slice(2,4)}`;
     return formattedTime
  } return '00.00.00'
 }


export const deadlineOrderPicker = (orderTime: string) => {
  
  const now = new Date();

  const [orderHours, orderMinutes, orderSeconds] = orderTime.split(":").map(Number);
  const nowInSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  const orderInSeconds = orderHours * 3600 + orderMinutes * 60 + orderSeconds;

  let differenceInSeconds = orderInSeconds - nowInSeconds;
  if (differenceInSeconds < 0) differenceInSeconds += 40 * 60;

  const minutesLeft = Math.floor((differenceInSeconds % 3600) / 60);
  const secondsLeft = Math.abs(differenceInSeconds % 60);
  const formattedMinutes = String(minutesLeft).padStart(2, "0");
  const formattedSeconds = String(secondsLeft).padStart(2, "0");
  return minutesLeft < 0 ? null : `${formattedMinutes}:${formattedSeconds}`
}

export const parseComboFood = (comboArray: ComboFood[]) => {
  const parsed = comboArray.map((item) => {
    return {
      id: item.id,
      title: item.title,
      price: item.price,
      promotion_price: item.promotion_price,
      image: item.image,
      description: item.description,
      category: 0,
      kcal: null,
      gram: null,
      ml: null,
      quan: null,
      nutritional_value: null,
      proteins: null,
      carbohydrates: null,
      ingredients: [],
      fat: 0,
      additives: [],
      slug: 'combo',
      often_orders_with: []
    }
  })
  return parsed
}

export const isToday = (date: string[]): boolean => {
  const today = new Date();
  const [day] = date;
  return (
    today.getDate() === parseInt(day) );
};

export const subtractMinutes = (time: string[], minutesToSubtract: number): string[] => {
  const [hours, minutes] = time;
  let newMinutes = parseInt(minutes) - minutesToSubtract;
  let newHours = parseInt(hours);
  
  if (newMinutes < 0) {
    newHours -= Math.ceil(Math.abs(newMinutes) / 60);
    newMinutes = 60 + (newMinutes % 60);
  }

  newHours = (24 + newHours) % 24;
  
  return [newHours.toString(), newMinutes.toString().padStart(2, "0")];
};
