import cls from './TabletOrderCard.module.scss';
import { ReactComponent as FireIco } from "../../../assets/icon/ico-fire.svg";
import { ReactComponent as WatchICo } from "../../../assets/icon/ico-watch-active.svg";
import { ReactComponent as WatchArrowsIco } from "../../../assets/icon/ico-watch-arrows.svg";
import { ReactComponent as FastFoodIco } from "../../../assets/icon/ico-fast-food.svg";
import { isToday, subtractMinutes } from '../../../utils/helpers';
import { useEffect, useState } from 'react';
import { TabletOrderCardType } from '../../types';

function TabletOrderCard({ active, time, count, inWork, deliver, code, date, handleOpenOrder }: TabletOrderCardType) {
  const [checkDateOrder, setCheckDateOrder] = useState<boolean>(true);
  const [checkTime, setCheckTime] = useState<boolean>(false);

  const times = time.split(' ');
  const tim = times[1].split(':');
  const orderTime = `${Number(tim[0]) + 6}:${tim[1]}`;
  const dates = times[0].split('-');
  const [datePart] = date.split(' ');
  const [year, month] = datePart.split('-');

  const newTime = subtractMinutes([String(orderTime), tim[1]], -40);

  useEffect(() => {
    setCheckDateOrder(isToday([year, month]));
  }, [date]);

  useEffect(() => {
    const dateEnd = new Date();
    dateEnd.setHours(Number(newTime[0]));
    dateEnd.setMinutes(Number(newTime[1]));
    dateEnd.setSeconds(0);

    const diff = dateEnd.getTime() - new Date().getTime();
    handlerTime(diff > 0);
  }, [newTime]);

  const handlerTime = (bool: boolean) => {
    setCheckTime(bool);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleOpenOrder();
      }}
      style={{ direction: 'ltr' }}
      className={`${active ? "bg-[#F5FFF5D9]" : "bg-white"} z-[2] w-[92%] min-w-[300px] h-[100px] min-h-[100px] rounded-xl flex items-center justify-between py-3 lg:py-4 px-[14px] lg:px-[18px]`}
    >
      <div className="w-[60%] flex flex-col items-start justify-between">
        <div className="w-full font-bold flex flex-col items-start">
          <p className={`flex items-center gap-3 m-0 text-4xl uppercase ${checkDateOrder ? (checkTime ? "text-[#33a937]" : "text-[darkred]") : "text-[darkred]"}`}>
            {deliver ? 'D' : 'C'}{code} {
              inWork ?
                <WatchICo className="w-[29px] h-[29px]" />
                :
                <FireIco className="w-[29px] h-[29px] fill-[#E83131]" />
            }
          </p>
          <div className="m-0 flex items-center justify-between text-2xl text-[#575757]">
            <WatchArrowsIco className="mt-1 w-6 h-6" />{dates[0]}.{dates[1]} - {orderTime}
          </div>
        </div>
      </div>
      <div className={`w-[80px] h-[64px] flex justify-center items-center border-0 rounded-xl text-[42px] font-bold text-[#575757] ${active ? "bg-[#E1FAE1]" : "bg-[#f5f5f5]"}`}>
        <FastFoodIco className="w-8 h-8" />{count}
      </div>
    </div>
  );
}

export default TabletOrderCard;
