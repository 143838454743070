import cls from "./ProductsTableRow.module.scss";
import { DetailProduct } from "../../../redux/Tablet/types";

function ProductsTableRow({
  image,
  title,
  status,
  quantity,
  started,
  index,
  orderItemId,
  setChecked, 
  setStart,
  id,
  changeDrink,
  changeSauce,
}: DetailProduct) {
  const handlerClick = (arg1: boolean, arg2: boolean, str: string) => {
    if (!started) {
      setStart();
    }
    setChecked(arg1, index, orderItemId, arg2);
  }
  
  return (
    // <div className={status ? cls["tableCellDone"] : cls["tableCell"]}>
    <div className={`${status ? "bg-[#E1FAE1]" : "bg-white" } w-full h-[98px] flex justify-between border-y border-solid border-[#dadada]`}>
      <div className="flex items-center max-w-[450px] w-full">
        <img alt={"позиция"} src={image} className="mx-4 min-w-[50px] min-h-[70px] max-w-[90px] max-h-[70px] lg:max-h-[98px]"/>
        <div className="w-auto">
          <p className="text-[#575757] text-xl lg:text-2xl font-extrabold">{title}</p>
          {(changeDrink !== undefined) && changeDrink.length > 0 && <p className="font-bold text-sm"><span className="text-[#8E8E8E]">Напиток:</span> <span className="text-[#575757]">{changeDrink[0].title}</span></p>}
          {(changeDrink !== undefined) && changeSauce.length > 0 && <p className="font-bold text-sm"><span className="text-[#8E8E8E]">Соус:</span> <span className="text-[#575757]">{changeSauce[0].title}</span></p>}
        </div>
      </div>
        <div className="flex items-center justify-between gap-3 lg:gap-10 max-w-[185px]">
          <div className="w-[130px] flex justify-start items-center">
            <p className="text-[#575757] text-2xl font-extrabold">X{quantity}</p>
          </div>
          <div className="w-[130px] flex justify-center items-center">
            <button
              onClick={() => {
                handlerClick(!status, status, '1');
              }}
              className={status ? cls["tableCell-buttonChecked"] : cls["tableCell-buttonCheck"]}
            ></button>
          </div>
        </div>
    </div>
  );
}
export default ProductsTableRow;
