import {useDispatch, useSelector} from "react-redux";
import {RootState, TypedDispatch} from "../../../redux/store";
import cls from './LogoutModal.module.scss'
import { setLoggingOutModal} from "../../../redux/Tablet/TabletSlice";
import Popup from "../../Popup/Popup";
import Button from "../../Buttons/Button/Button";
import BorderedButton from "../../Buttons/BorderedButton/BorderedButton";
import { useNavigate } from "react-router-dom";
import { setLogin } from "../../../redux/User/UserSlice";
import { collectorLogout } from "../../../redux/User/action";

function LogoutModal(){
  const navigate = useNavigate();
  const {loggingOutModalOpen} = useSelector((state: RootState) => state.tablet)
  const dispatch = useDispatch<TypedDispatch>()
  const handleCloseModal = () => {
    dispatch(setLoggingOutModal(false))
  }

  const handleLogout = () => {
    let phoneNumber = JSON.parse(localStorage.getItem('collectors') || '{}')
    dispatch(collectorLogout(phoneNumber.collector_phone))
    dispatch(setLogin(false))
    dispatch(setLoggingOutModal(false))
    navigate('/login')
  }
  return (
    <Popup open={loggingOutModalOpen} setClose={handleCloseModal}>
      <div className="p-8">
        <h2 className="mb-8 text-4xl font-extrabold text-center text-[#575757]">вы точно хотите выйти?</h2>
        <div className="flex items-center justify-center gap-5">
          <BorderedButton
            text={'да'}
            maxwidth={250}
            fontsize={20}
            fontweight={700}
            OnClickBtn={handleLogout}
          />
          <Button
            disabled={false}
            text={'нет'}
            maxwidth={250}
            fontsize={20}
            fontweight={700}
            handlerClick={handleCloseModal}
          />
        </div>
      </div>
    </Popup>
  )
}
export default LogoutModal