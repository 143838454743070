import React from 'react'
import cls from "./TabletOrderList.module.scss"
import { ReactComponent as IconEmpty } from "../../../assets/icon/icon-tablet-empty.svg";


const Empty = ({text}: {text: string}) => {
  return (
    <div className="w-full h-[70%] flex items-center justify-center flex-col">
        <IconEmpty/>
        <h3 className='m-0 mt-[34px] mb-2 text-[28px] font-extrabold text-[#575757]'>Ой, тут пусто</h3>
        <p className='text-lg font-semibold m-0'>{text}</p>
    </div>
  )
}

export default Empty 