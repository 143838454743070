import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./User/UserSlice";
import TabletSlice from "./Tablet/TabletSlice";


export const store = configureStore({
  reducer: {
    User: UserSlice,
    tablet: TabletSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type TypedDispatch = typeof store.dispatch;

export default store;
