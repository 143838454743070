import React from "react";
import cls from './Loader.module.scss'
import {ReactComponent as IconLoader } from '../../assets/icon/ico-loader.svg'



export default function Loader(){
    return (
        <div className={cls.container}>
            <div className={cls.loader}>
                <div className={cls.ani}>
                    <IconLoader />
                </div>
            </div>
        </div>
    )
}