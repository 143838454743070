import cls from './TabletSearch.module.scss'
import {SyntheticEvent, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState, TypedDispatch} from "../../../redux/store";
import {setOpenOrders, setSearching, setShowingOrder} from "../../../redux/Tablet/TabletSlice";
import {ReactComponent as IcoSearch} from "../../../assets/icon/ico-search.svg";
import TabletOrderCard from "../TabletOrderCard/TabletOrderCard";
import {TabletOrders} from "../../../redux/Tablet/types";
import Loader from "../../Loader/Loader";

function TabletSearch(){

  const dispatch = useDispatch<TypedDispatch>()
  const [searchingValue, setSearchingValue] = useState<string>('')
  const [searchingOrders, setSearchingOrders] = useState<TabletOrders[]>([])
  const { activeTab, loading, waitingOrders, newOrders, assemblingOrders} = useSelector((state: RootState) => state.tablet)
  const [allOrders, setAllOrders] = useState([...waitingOrders.orders , ...newOrders.orders, ...assemblingOrders.orders])
  const myInputRef = useRef<HTMLInputElement | null>(null);


  const setOpenSearch = (event: SyntheticEvent) => {
    event.stopPropagation()
    dispatch(setSearching(false))
  }

  useEffect(() => {
    if (myInputRef.current) {
      myInputRef.current.focus();
    }
    
    if(String(searchingValue)!== ''){
      setSearchingOrders(allOrders.filter(item => {return  String(item.id).includes(String(searchingValue))}))
    }
  }, [searchingValue]);

  const handleOpenSearchedOrder = (order: TabletOrders) => {
    dispatch(setShowingOrder(order))
    dispatch(setSearching(false))
    dispatch(setOpenOrders({activeTab: activeTab, types: order.status === 3 ? 'assembling' : (order.status === 2 ? 'new' : 'waiting'), ids: order.id }));
  }

  return (
    <div
      className={cls['search']}
      onClick={(event) => setOpenSearch(event)}
    >
      <div className={cls['search__header']} onClick={(e) => e.stopPropagation()}>
        <div>
          <IcoSearch className={cls['TabletSearch__searchBtn-ico']} />
          <input
            type='number'
            tabIndex={1}
            ref={myInputRef && myInputRef} 
            onChange={(event) => setSearchingValue(event.target.value)}
            className={cls['search__header-input']}
            placeholder='ВВЕДИТЕ НОМЕР ЗАКАЗА'
            value={searchingValue}
          />
        </div>
        <button
          className={cls['search__header-btn']}
          onClick={(event) => setOpenSearch(event)}
        ></button>
      </div>
      <div className={cls['search__cardList']}>
        {
          loading &&
					<div className={cls['search__cardList-loading']}>
						<Loader />
					</div>
        }
        {
          searchingOrders.length > 0 && searchingValue !== '' &&
          searchingOrders.map((item) => {
            return <TabletOrderCard
              handleOpenOrder={() => handleOpenSearchedOrder(item)}
              key={item.id}
              deliver={item.deliver}
              code={item.id}
              active={item.active}
              time={item.date}
              date={item.date}
              count={item.quantity}
              inWork={item.status === 3}
            />
          })
        }
      </div>
    </div>
  )
}


export default TabletSearch