import {createAsyncThunk} from "@reduxjs/toolkit";

import {
    COLLECT_START, 
    COLLECTOR_PRINT_RECEIPT,
    GET,
    GET_COLLECTOR_ACCEPT,
    GET_COLLECTOR_NEW,
    GET_COLLECTOR_ORDERS_ASSEMBLING,
    GET_COLLECTOR_WAITING_ORDERS,
    PAGE_SIZE_COLLECTOR,
    PATCH,
    POST,
    POST_CHANGE_STATUS
} from "../../utils/constants";

import requestFunc from "../../utils/mainFunc";

import {
    CollectBeginParams,
    PatchCheckedOrderStatusParams,
    PrintReceiptParams,
    QueryParamsOrderEndType,
    QueryParamsType,
} from "./types";

// waiting order to end order
export const patchOrderEnd = createAsyncThunk(
  'tablet/patchOrderEnd',
  async(params: QueryParamsOrderEndType, thunkApi) => {
    const reqParams = {
      type: PATCH,
      url: `${process.env.REACT_APP_BASE_URL}${GET_COLLECTOR_ACCEPT}`,
      body: {
        order_id: params.id
      },
      params: null
    };
    
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

//new
export const getCollectorOrder = createAsyncThunk(
  'tablet/getCollectorOrder',
  async(params: QueryParamsType, thunkApi) => {
    const reqParams = {
      type: GET,
      url: `${process.env.REACT_APP_BASE_URL}${GET_COLLECTOR_NEW}?page=1&page_size=${PAGE_SIZE_COLLECTOR * params.currentPage}`,
      body: null,
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

// assembling
export const getAssemblingOrders = createAsyncThunk(
  'tablet/getAssembling',
  async(params:QueryParamsType, thunkApi) => {
    const reqParams = {
      type: GET,
      url: `${process.env.REACT_APP_BASE_URL}${GET_COLLECTOR_ORDERS_ASSEMBLING}?page=1&page_size=${PAGE_SIZE_COLLECTOR * params.currentPage}`,
      body: null,
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

// waiting

export const getWaitingOrders = createAsyncThunk(
  'tablet/getWaitingOrders',
  async(params:QueryParamsType, thunkApi) => {
    const reqParams = {
      type: GET,
      url: `${process.env.REACT_APP_BASE_URL}${GET_COLLECTOR_WAITING_ORDERS}?page=1&page_size=${PAGE_SIZE_COLLECTOR * params.currentPage}`,
      body: null,
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const patchCheckedOrderStatus = createAsyncThunk(
  'tablet/collectBegin',
  async (params:PatchCheckedOrderStatusParams , thunkApi) => {
    const reqParams = {
      type: PATCH,
      url: `${process.env.REACT_APP_BASE_URL}${POST_CHANGE_STATUS}`,
      body: {
        order_items: [params.orderItemId],
        status: params.status
      },
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const collectBegin = createAsyncThunk(
  'tablet/collectBegin',
  async (params:CollectBeginParams , thunkApi) => {
    const reqParams = {
      type: POST,
      url: `${process.env.REACT_APP_BASE_URL}${COLLECT_START}`,
      body: {
        order_id: params.orderId
      },
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const printReceipt = createAsyncThunk(
    'tablet/printReceipt',
    async (params:PrintReceiptParams, thunkApi ) => {
        const reqParams = {
            type: PATCH,
            url: `${process.env.REACT_APP_BASE_URL}${COLLECTOR_PRINT_RECEIPT}`,
            body: {
                order_id: params.orderId
            },
            params: null
        };
        try {
            return await requestFunc(reqParams)
        }
        catch (error){
            return thunkApi.rejectWithValue(error)
        }
    }
)