import {
  CollectorOrdersType,
  IOpenOrder,
  PayloadToChangeChecked,
  Tablet,
  TabletOrders,
  TabletOrdersTab,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collectBegin,
  getAssemblingOrders,
  getCollectorOrder,
  getWaitingOrders,
  printReceipt,
} from "./actions";
import {
  parserForCollectorFoods,
  setShowingOrderToState,
} from "../../utils/helpers";
import {
  PAGE_SIZE_COLLECTOR,
  TabletOrder,
} from "../../utils/constants";

const initialState: Tablet = {
  blockedScreen: false,
  activeTab: "new",
  searching: false,
  loggingOutModalOpen: false,
  idNotification: "",
  loading: false,
  loadingPagination: false,
  // loadingPrintReceipt: false,
  showData: [],
  newOrders: {
    currentPage: 1,
    count: 0,
    totalPages: 1,
    orders: [],
  },
  ordersShouldUpdate: true,
  waitingOrders: {
    currentPage: 1,
    count: 0,
    totalPages: 1,
    orders: [],
  },
  assemblingOrders: {
    currentPage: 1,
    count: 0,
    totalPages: 1,
    orders: [],
  },
  showingOrder: TabletOrder,
  openOrder: {
    types: "",
    ids: 0,
    activeTab: ""
  },

};
// 1 tab waitingOrders:  ожидают клиент
// 2 tab newOrders: новые
// 3 tab assemblingOrders: Собираются

export const TabletSlice = createSlice({
  name: "tablet",
  initialState: initialState,
  reducers: {
    setLoading: (state: Tablet, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSearching: (state: Tablet, action: PayloadAction<boolean>) => {
      state.searching = action.payload;
    },
    setOpenOrders: (state: Tablet, action: PayloadAction<IOpenOrder>) => {
      const { activeTab, newOrders, waitingOrders, assemblingOrders } = state;
      const { ids } = action.payload;

      state.openOrder = action.payload;
      const updateOrderList = (orderList: TabletOrders[]) => {
        orderList.forEach((item: any) => {
          if (item.id === ids) {
            item.active = true;
            state.showingOrder = item
          }
           else {item.active = false;}
        });
        
      };
      switch (activeTab) {
        case "new":
          updateOrderList(newOrders.orders);
          break;
        case "waiting":
          updateOrderList(waitingOrders.orders);
          break;
        case "assembling":
          updateOrderList(assemblingOrders.orders);
          break;
      }
      state.activeTab = action.payload.types
    },
    setCloseOrders: (state: Tablet) => {
      const {
        activeTab,
        openOrder,
        newOrders,
        waitingOrders,
        assemblingOrders,
      } = state;

      if (openOrder.types === activeTab) {
        const closeOrderItem = (item: any) => item.id === openOrder.ids && (item.active = false);

        const closeOrdersInList = (orderList: TabletOrders[]) => {orderList.some(closeOrderItem)};

        switch (activeTab) {
          case "new":
            closeOrdersInList(newOrders.orders);
            break;
          case "waiting":
            closeOrdersInList(waitingOrders.orders);
            break;
          case "assembling":
            closeOrdersInList(assemblingOrders.orders);
            break;
          default:
            break;
        }
      }
    },
    setActiveTab: (state: Tablet, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
      switch (state.activeTab) {
        case "new":
          state.newOrders = state.newOrders;
          if (state.newOrders.orders.length > 0) {
            state.showingOrder = state.newOrders.orders[0];
          } else {
            state.showingOrder = { ...TabletOrder };
          }
          break;

        case "waiting":
          if (state.waitingOrders.orders.length > 0) {
            state.showingOrder = state.waitingOrders.orders[0];
          } else {
            state.showingOrder = { ...TabletOrder };
          }
          break;

        case "assembling":
          if (state.assemblingOrders.orders.length > 0) {
            state.showingOrder = state.assemblingOrders.orders[0];
          } else {
            state.showingOrder = { ...TabletOrder };
          }
          break;
      }
    },
    setBlockedPage: (state: Tablet, action: PayloadAction<boolean>) => {
      state.blockedScreen = action.payload;
    },
    setIdNotification: (state: Tablet, action: PayloadAction<string>) => {
      state.idNotification = action.payload;
    },
    setLoggingOutModal: (state: Tablet, action: PayloadAction<boolean>) => {
      state.loggingOutModalOpen = action.payload;
    },
    setTabletCurrentPage: (state: Tablet, action: PayloadAction<number>) => {
      if (state.activeTab === "new") {
        state.newOrders.currentPage = action.payload;
      } else if (state.activeTab === "assembling") {
        state.assemblingOrders.currentPage = action.payload;
      } else if (state.activeTab === "waiting") {
        state.waitingOrders.currentPage = action.payload;
      }
    },
    setShowingOrder: (state: Tablet, action: PayloadAction<TabletOrders>) => {
      state.showingOrder = action.payload;
    },
    setOrdersShouldUpdate: (state: Tablet, action: PayloadAction<boolean>) => {
      state.ordersShouldUpdate = action.payload;
    },

    setStartedCollect: (state: Tablet) => {
      const showingOrder = { ...state.showingOrder };

      state.newOrders = setShowingOrderToState(state.newOrders, {
        ...showingOrder,
        status: 3,
      });
      state.showingOrder = { ...showingOrder, started: true };
    }, // to local

    // переписать!!!!!!!!!!!!!!!!!!!!!!!!
    setCheckedShowingOrder: (
      state: Tablet,
      action: PayloadAction<PayloadToChangeChecked>
    ) => {
      state.showingOrder.items[action.payload.index].status =
        action.payload.check;
      state.assemblingOrders.orders.map(
        (item) =>
          item.id === state.showingOrder.id &&
          (item.items[action.payload.index].status = action.payload.check)
      );
      const rawOrders = state.newOrders.orders.map((order) =>
        order.id === state.showingOrder.id ? state.showingOrder : order
      );
      state.newOrders.orders = rawOrders;
    },
    ////

    setReadyShowingOrder: (state: Tablet, action: PayloadAction<boolean>) => {
      state.showingOrder.ready = action.payload;
    },
  
    setFirstShowingOrder: (state: Tablet) => {
      state.showingOrder = TabletOrder;
    },
    setWaitingOrders: (
      state: Tablet,
      action: PayloadAction<{ states: TabletOrdersTab; id: number }>
    ) => {
      const { states, id } = action.payload;
      const ordersFilter = states.orders.filter((item) => item.id !== id);
      const stateOrder =  ordersFilter.map((item) => item.id === ordersFilter[0].id ? { ...item, active: true } : item);
      state.showingOrder = stateOrder[0];
      state.waitingOrders.orders = stateOrder;
    },
  },

  extraReducers: (builder) => {
    // get orders
    builder.addCase(getCollectorOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectorOrder.fulfilled, (state, action: PayloadAction<CollectorOrdersType>) => {
      state.loading = false
      if (action.payload.data.results.length > 0) {
        state.loading = false;
        action.payload.data.results[0].active = true;
        state.newOrders.orders = parserForCollectorFoods(
          action.payload.data.results
        );
        state.newOrders.count = action.payload.data.count;
        state.newOrders.totalPages = Math.ceil(
          action.payload.data.count / PAGE_SIZE_COLLECTOR
        );
      } else {
        state.newOrders.orders = []
        state.newOrders.count = 0
      }
    });
    builder.addCase(getCollectorOrder.rejected, (state) => {
      state.loading = false;
    });

    // in proccess
    builder.addCase(getAssemblingOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAssemblingOrders.fulfilled,
      (state, action: PayloadAction<CollectorOrdersType>) => {
        state.loading = false;
        state.assemblingOrders.orders = parserForCollectorFoods(
          action.payload.data.results
        );
        state.assemblingOrders.count = action.payload.data.count;
        state.assemblingOrders.totalPages = Math.ceil(
          action.payload.data.count / PAGE_SIZE_COLLECTOR
        );
      }
    );
    builder.addCase(getAssemblingOrders.rejected, (state) => {
      state.loading = false;
    });

    // waiting
    builder.addCase(getWaitingOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getWaitingOrders.fulfilled,
      (state, action: PayloadAction<CollectorOrdersType>) => {
        state.loading = false;
        state.waitingOrders.orders = parserForCollectorFoods(
          action.payload.data.results
        );
        state.waitingOrders.count = action.payload.data.count;
        state.waitingOrders.totalPages = Math.ceil(
          action.payload.data.count - PAGE_SIZE_COLLECTOR
        );
      }
    );
    builder.addCase(getWaitingOrders.rejected, (state) => {
      state.loading = false;
    });

    // begins a order
    builder.addCase(collectBegin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(collectBegin.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(collectBegin.rejected, (state) => {
      state.loading = false;
    });

    // // print receipt
    // builder.addCase(printReceipt.pending, (state) => {
    //   state.loadingPrintReceipt = true;
    // });
    // builder.addCase(printReceipt.rejected, (state) => {
    //   state.loadingPrintReceipt = false;
    // });
  },
});
export const {
  setSearching,
  setActiveTab,
  setBlockedPage,
  setLoggingOutModal,
  setTabletCurrentPage,
  setShowingOrder,
  setOrdersShouldUpdate,
  setStartedCollect,
  setCheckedShowingOrder,
  setReadyShowingOrder,
  setFirstShowingOrder,
  setIdNotification,
  setOpenOrders,
  setCloseOrders,
  setWaitingOrders,
  setLoading,
} = TabletSlice.actions;
export default TabletSlice.reducer;
