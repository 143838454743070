import {ReactElement} from 'react'

export enum errorStatusVariant {
  error = "#E83131",
  success = "#F9EB1D"
};

export interface PropsModal {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element) | ReactElement | null
  open: boolean;
  setOpen: () => void;
}
export interface ReactChildrenComponent {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element) | ReactElement | null
  status:  errorStatusVariant
}

export interface TabletTabs {
  waiting: null | string | number
  newOrder: null | string | number
  assembling: null | string | number
  handleSearchOpen: () => void
}

export interface ButtonType {
  text: string
  maxwidth?: number
  fontsize?: number
  fontweight?: number
  OnClickBtn: () => void
};

export interface TabletOrderCardType {
  active: boolean // active or not, collecting now this order
  time: string
  date: string
  count: string
  inWork: boolean // for icon fire or time
  deliver: boolean
  code: number
  handleOpenOrder: (() => void)
}

export interface TabletDetailPrintProp{
  handlePrintReceipt: (() => void)
  ready: boolean
  text: string
  goodToGo?: boolean
}

