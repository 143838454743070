import {ReactComponent as ReceiptIco} from "../../../assets/icon/ico-receipt.svg";
import {ReactComponent as ArrowIco} from "../../../assets/icon/ico-arrow-right.svg";
import cls from './PrintReceipt.module.scss'
import {TabletDetailPrintProp} from "../../types";

function PrintReceipt({ ready, handlePrintReceipt, text, goodToGo}:TabletDetailPrintProp) {
  return(
    <button
      // className={ready ? cls['receiptActive'] : cls['receipt']}
      className={`text-base py-[14px] px-[18px] lg:py-[20px] lg:px-[30px] rounded-[80px]  border-0 outline-none text-white xl:text-[18px] font-bold uppercase flex items-center justify-center gap-[10px] cursor-pointer absolute right-5 ${ready ? "bg-[#33a937]" : "bg-[#BABABA]"}`}
      onClick={() => handlePrintReceipt()}
      disabled={!ready}
    >
      {
        !goodToGo &&
        <ReceiptIco className="w-4 h-4 lg:w-6 lg:h-6 mr-[2px]"/>
      }
      {text}
      {
        goodToGo &&
        <ArrowIco className="w-4 h-4 lg:w-6 lg:h-6 mr-[2px]" />
      }
    </button>
  )
}

export default PrintReceipt