import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Auth from './pages/Auth';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import Loader from './components/Loader/Loader';
import RequireAuth from './pages/RequireAuth';
import PopupError from './components/Popup/PopupErorr/PopupError';

const App = () => {
  const { loading } = useSelector((state: RootState) => state.tablet)
  const { errorRequest } = useSelector((state: RootState) => state.User)

  return (
    <> 
    {loading && <Loader/>}
    {errorRequest && <PopupError />}
    <Routes>
      <Route path='/' element={
        <RequireAuth>
          <Home />
        </RequireAuth>
      }/>
      <Route path='/login' element={<Auth />}/>
    </Routes>
    </>
  )
}

export default App