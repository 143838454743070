import Loader from "../Loader/Loader";
import cls from "./Buttom.module.scss";

type ButtonType = {
  id?: string;
  text: string | React.ReactNode;
  disabled?: boolean;
  maxwidth?: number | string;
  fontsize?: number;
  fontweight?: number;
  loading?: boolean;
  handlerClick?: () => string | void | boolean;
  
};

export default function Button({
 
  id,
  text,
  maxwidth,
  fontsize,
  fontweight,
  handlerClick,
  disabled,
  loading,
}: ButtonType) {
  ;
  return (
    <button
      id={id}
      className={cls.button}
      disabled={disabled}
      style={{ maxWidth: maxwidth, fontSize: fontsize, fontWeight: fontweight }}
      onClick={handlerClick}
    >
      {text}
      {loading && <Loader />}
    </button>
  );
}
