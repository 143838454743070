import cls from './TabletNotification.module.scss'
import Logo from "../../../assets/icon/ico-notification.svg";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';


interface notificationType {
 notification: boolean
}

const TabletNotification = ({notification}:notificationType) => {
  const id = useSelector((state: RootState) => state.tablet.idNotification)

  return (
    <div className={notification ? cls['notification'] : cls['noneNotification']}>
      <div className={notification ? cls['notification__active']: cls['notification__noActive']}>
      <div className={cls['notification__logo']}>
        <img src={Logo} alt="" />
      </div>
      <div className={cls['notification__title']}>
        <span>Заказ:</span>
        <span>{id}</span>
        <span>собран.</span>
        <span>Спасибо за работу!</span>
      </div>
    </div>
    </div>
  )
}

export default TabletNotification