export const TOKEN_REFRESH = '/token/refresh/'


export const POST_CHANGE_STATUS = '/orders/order-item-status/'
export const COLLECTOR_PRINT_RECEIPT = '/collector/collect-ends/'
export const COLLECT_START = '/collector/collect-start/'
export const LOGIN_COLLECTOR = '/collector/signin/'
export const LOGOUT_COLLECTOR = '/collector/signout/'
export const GET_COLLECTOR_NEW = '/collector/orders/'
export const GET_COLLECTOR_ORDERS_ASSEMBLING = '/collector/collecting-orders/'
export const GET_COLLECTOR_ACCEPT = '/collector/collector-accept/'
export const GET_COLLECTOR_WAITING_ORDERS = '/collector/orders-waiting/'

export const GET = 'get'
export const POST = 'post'
export const PATCH = 'patch'

export const MONTHsRU = [
  'ЯНВАРЯ',
  'ФЕВРАЛЯ',
  'МАРТА',
  'АПРЕЛЯ',
  'МАЯ',
  'ИЮНЯ',
  'ИЮЛЯ',
  'АВГУСТА',
  'СЕНТЯБРЯ',
  'ОКТЯРБРЯ',
  'НОЯБРЯ',
  'ДЕКАБРЯ'
]

export const PAGE_SIZE_COLLECTOR = 20

export const TabletOrder = {
  date: '',
  id: 0,
  quantity: '',
  items: [],
  status: 0,
  deliver: false,
  time: '',
  active: false,
  started: false,
  ready: false
}
