import { createAsyncThunk } from "@reduxjs/toolkit";
import { POST, LOGIN_COLLECTOR, LOGOUT_COLLECTOR } from "../../utils/constants";
import requestFunc, { CollectorsType } from "../../utils/mainFunc";
import { LoginParams } from "../Tablet/types";
import { Axios } from "axios";

export const collectorLogout = createAsyncThunk(
  'tablet/logout',
  async(params: string, thunkApi) => {
    const reqParams = {
      type: POST,
      url: `${process.env.REACT_APP_BASE_URL}${LOGOUT_COLLECTOR}`,
      body: {
        phone_number: params,
      },
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const logIn = createAsyncThunk(
  'tablet/logIn',
  async(params: LoginParams, thunkApi) => {
    const reqParams = {
      type: POST,
      url: `${process.env.REACT_APP_BASE_URL}${LOGIN_COLLECTOR}`,
      body: {
        phone_number: params.phone_number,
        password: params.password
      },
      params: null
    };
    try{
      return await requestFunc(reqParams)
    }
    catch (error){
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const refreshToken = async (axios: Axios, info: CollectorsType) => {
  try {
    const res = await axios.request({
      method: 'POST',
      baseURL: `https://issmile.kz/api/token/refresh/`,
      data: {
        refresh: info.rToken
      },
    });

    if (res && res.data) {
      const userObj = {...info, token: res.data.access};
      localStorage.setItem("collectors", JSON.stringify(userObj));
      return res;
    } else {
      throw new Error('Invalid response');
    }
  } catch (err) {
    console.error(err);
    localStorage.clear();
    throw err;
  }
}