import TabletHeaderTabs from "../TabletHeaderTabs/TabletHeaderTabs";
import cls from './TabletHeader.module.scss'
import TabletLogoutBlock from "../TabletLogoutBlock/TabletLogoutBlock";
import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../../redux/store";
import { setSearching } from "../../../redux/Tablet/TabletSlice";
import { useEffect } from "react";
function TabletHeader() {
  const countWaiting = useSelector((state: RootState) => state.tablet.waitingOrders.count)
  const countNewOrders = useSelector((state: RootState) => state.tablet.newOrders.count)
  const countAssemblingOrders = useSelector((state: RootState) => state.tablet.assemblingOrders.count)

  const dispatch = useDispatch<TypedDispatch>()

  const handleSearchOpen = () => {
    dispatch(setSearching(true))
  }
  
  useEffect(() => {
  }, [countWaiting])
  
  return (
    <div className={cls['header']}>
      <div className="pl-5 max-lg:pl-6">
        <TabletHeaderTabs handleSearchOpen={handleSearchOpen} waiting={countWaiting} newOrder={countNewOrders} assembling={countAssemblingOrders} />
      </div>
      <div className="pr-5 max-lg:pr-6">
        <TabletLogoutBlock />
      </div>
    </div>
  )
}
export default TabletHeader