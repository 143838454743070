import cls from './BlockedScreen.module.scss'
import {useDispatch} from "react-redux";
import {TypedDispatch} from "../../../redux/store";
import {setBlockedPage} from "../../../redux/Tablet/TabletSlice";
import {ReactComponent as LockIcon} from "../../../assets/icon/ico-lock.svg";

function BlockedScreen(){
  const dispatch = useDispatch<TypedDispatch>()
  const handleUnblock = () =>{
    dispatch(setBlockedPage(false))
  }
  return(
    <div className="w-full h-screen bg-[#000000d1] flex items-center justify-center absolute top-0 left-0 z-[4]">
      <div className="w-[420px] h-auto min-h-[150px] bg-[#F5FFF5D9] rounded-2xl py-5 px-8 flex flex-col justify-evenly gap-2.5 text-[#575757] text-center uppercase">
        <h2 className='m-0 text-[28px] font-extrabold'>
          экран находится в режиме блокировки
        </h2>
        <button onClick={() => handleUnblock()} className="p-[10px] uppercase text-white border-0 rounded-xl text-[18px] font-bold  flex justify-center gap-4 bg-[#33a937]">
          <LockIcon/> разблокировать
        </button>
      </div>
    </div>
  )
}

export default BlockedScreen