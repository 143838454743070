import cls from './TabletLogoutBlock.module.scss'
import { ReactComponent as LockIco } from "../../../assets/icon/ico-unlock.svg";
import { ReactComponent as LogoutIco } from "../../../assets/icon/ico-logout.svg";
import { useDispatch } from "react-redux";
import { TypedDispatch } from "../../../redux/store";
import { setBlockedPage, setLoggingOutModal } from "../../../redux/Tablet/TabletSlice";
import { MONTHsRU } from "../../../utils/constants";
import { useEffect, useState } from "react";

function TabletLogoutBlock() {
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 10000);
    return () => clearInterval(interval);
  }, [])

  const hours = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
  const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();;
  const month = MONTHsRU[time.getMonth()]
  const date = time.getDate()
  const collector = JSON.parse(localStorage.getItem('collectors') || '{}');
  const timeString = `${date} ${month} | ${hours}:${minutes}`

  const dispatch = useDispatch<TypedDispatch>()

  const handleBlockPage = () => { 
    dispatch(setBlockedPage(true))
  }

  const handleOpenLogoutModal = () => {
    dispatch(setLoggingOutModal(true))
  }

  return (
    <div className="w-full flex items-center gap-2.5 max-lg:gap-7">
      <div className="text-right font-bold text-[#9e9e9e] flex flex-col gap-1">
        <p className="text-sm lg:text-base leading-[110%]">{timeString}</p>
        <p className="text-sm lg:text-base leading-[110%]">{collector.address}</p>
      </div>
      <div className="flex items-center gap-3.5">
        <button onClick={() => handleBlockPage()} className="bg-[#F4F4F4]">
          <LockIco className={cls.blockIco} /> 
          <span className="hidden lg:block text-text-lg font-bold leading-[22px]">заблокировать</span>
          {/* <span className={cls['logoutBlock__buttons__tablet']}></span> */}
        </button>
        <button
          onClick={() => handleOpenLogoutModal()}
          className="bg-[#F4F4F4] p-2.5"
        >
          <LogoutIco />
        </button>
      </div>
    </div>
  )
}
export default TabletLogoutBlock